import { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Divider,
  Button,
  Modal,
  IconButton,
  TableCell,
  TableRow,
  Table,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import TableHeader from './TableHeader';
import { FontAwesome } from '@src/Components/common';
import FileUpload from '@src/Components/common/buttons/FileUpload';
import PricingModal from './PricingModal';
import VariationsApi from '@oneAppCore/services/VariationsApi';
import { mutate } from 'swr';
import AsyncSelect from '@src/Components/common/Selects/AsyncSelect';
import { primary } from '@src/Components/Theme/themes/constants';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';

const years = () => {
  const now = new Date().getUTCFullYear();
  return Array(now - (now - 100))
    .fill('')
    .map((v, idx) => now - idx) as Array<number>;
};

const VariationBox = ({
  index,
  variation,
  onChange,
  makes,
  models,
  updateFitment,
  fitment,
  deleteRow,
  row,
  imageCell,
  setImageCell,
  cellIndex,
  mutate,
  formLength,
  varOptions,
  skuError,
  mpnError,
  columns,
  removeVariation,
}) => {
  const [url, setUrl] = useState('');
  const [infoText, setInfoText] = useState<string>('Inventory Info');
  const [disabled, setDisabled] = useState(true);
  const [savedImages, setSavedImages] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (formLength > 1) {
      setInfoText(`Variation ${index + 1} Info`);
    } else {
      setInfoText('Inventory Info');
    }
  }, [formLength, index]);

  const CustomColorIconButton = withStyles({
    root: {
      color: 'crimson',
    },
  })(IconButton);

  const filterMakes = (makes, years) => {
    return makes.filter(
      (e) => e.year.filter((el) => years.includes(String(el))).length,
    );
  };
  // const filterModels = (models, make) => {
  //   return models.filter((e) => make === e.make);
  // };

  const filterModels = (searchFilter: string, models, fit) => {
    try {
      if (!Array.isArray(models)) {
        return [];
      }
      if (searchFilter.length < 2) {
        return [];
      }

      const filteredModels = models.filter(
        (e) =>
          fit.make === e.make &&
          e.model.toLowerCase().includes(searchFilter.toLowerCase()),
      );

      return filteredModels;
    } catch (error) {
      console.error('Error filtering models:', error);
      return [];
    }
  };

  const saveImagesToLocalStorage = (images) => {
    const existingImages = localStorage.getItem('varImages') || '[]';
    const updatedImages = JSON.stringify([
      ...JSON.parse(existingImages),
      ...images,
    ]);
    localStorage.setItem('varImages', updatedImages);
    setSavedImages(JSON.parse(updatedImages));
  };

  useEffect(() => {
    const existingImages = localStorage.getItem('varImages');
    if (existingImages) {
      setSavedImages(JSON.parse(existingImages));
    }
  }, []);

  // const handleChangeModel = (selectedOption) => {
  //   if (selectedOption) {
  //     const selectedModelId = selectedOption.id;
  //     setNewymm((ymm) => ({
  //       ...ymm,
  //       model: selectedModelId,
  //     }));
  //   } else {
  //     setNewymm((ymm) => ({
  //       ...ymm,
  //       model: '',
  //     }));
  //   }
  // };

  const varTextLabel =
    varOptions === 'make'
      ? 'Vehicle Make'
      : varOptions === 'size'
      ? 'Size'
      : varOptions === 'color'
      ? 'Color'
      : varOptions === 'style'
      ? 'Style'
      : varOptions === 'material'
      ? 'Material'
      : 'Select a variant!';

  const updateImageUrl = async (url) => {
    const newImage = url[0];
    await VariationsApi.updateImage(
      { url: newImage },
      variation?.imageId,
    ).then(() => mutate());
  };

  const showModal = () => {
    cellIndex === imageCell ? setImageCell() : setImageCell(cellIndex);
  };

  return (
    <Grid
      item
      container
      key={index}
      alignItems="center"
      justifyContent="space-evenly"
      style={{ padding: 20 }}
    >
      <Grid item container>
        <Typography variant="h6">{infoText}</Typography>
      </Grid>
      <Table style={{ border: '2px solid #f3f2f7', borderRadius: 10 }}>
        <TableHeader variation={formLength} />

        <TableRow style={{ borderBottom: 'none' }}>
          {columns?.map((column) => {
            if (column.keyName === 'title') {
              return formLength > 1 ? (
                <TableCell
                  key={column.keyName}
                  style={{ borderBottom: 'none' }}
                >
                  <TextField
                    style={{
                      width: '100%',
                      backgroundColor: primary.transparent.main,
                    }}
                    variant="outlined"
                    label={varTextLabel}
                    value={variation?.variation ?? ''}
                    onChange={(e) =>
                      onChange(index, 'variation', e.currentTarget.value)
                    }
                  />
                </TableCell>
              ) : (
                <></>
              );
            } else if (column.keyName === 'sku') {
              return (
                <TableCell
                  key={column.keyName}
                  style={{ borderBottom: 'none' }}
                >
                  <TextField
                    style={{
                      width: '100%',
                      backgroundColor: primary.transparent.main,
                    }}
                    variant="outlined"
                    label="SKU"
                    value={variation?.sku ?? ''}
                    required
                    error={skuError}
                    onChange={(e) =>
                      onChange(index, 'sku', e.currentTarget.value)
                    }
                  />
                </TableCell>
              );
            } else if (column.keyName === 'manufacturerNo') {
              return (
                <TableCell
                  key={column.keyName}
                  style={{ borderBottom: 'none' }}
                >
                  <TextField
                    style={{
                      width: '100%',
                      backgroundColor: primary.transparent.main,
                    }}
                    variant="outlined"
                    label="MPN"
                    value={variation?.manufacturerNo ?? ''}
                    required
                    error={mpnError}
                    onChange={(e) =>
                      onChange(index, 'manufacturerNo', e.currentTarget.value)
                    }
                  />
                </TableCell>
              );
            } else if (column.keyName === 'upc') {
              return (
                <TableCell
                  key={column.keyName}
                  style={{ borderBottom: 'none' }}
                >
                  <TextField
                    style={{
                      width: '100%',
                      backgroundColor: primary.transparent.main,
                    }}
                    variant="outlined"
                    label="UPC"
                    value={variation?.upc ?? ''}
                    onChange={(e) =>
                      onChange(index, 'upc', e.currentTarget.value)
                    }
                  />
                </TableCell>
              );
            } else if (column.keyName === 'quantityPerBox') {
              return (
                <TableCell
                  key={column.keyName}
                  style={{ borderBottom: 'none' }}
                >
                  <TextField
                    style={{
                      width: '100%',
                      backgroundColor: primary.transparent.main,
                    }}
                    variant="outlined"
                    label="Qty/Box"
                    onChange={(e) =>
                      onChange(index, 'quantityPerBox', e.currentTarget.value)
                    }
                  />
                </TableCell>
              );
            } else if (column.keyName === 'map') {
              return (
                <TableCell
                  key={column.keyName}
                  style={{ borderBottom: 'none' }}
                >
                  <TextField
                    style={{
                      width: '100%',
                      backgroundColor: primary.transparent.main,
                    }}
                    variant="outlined"
                    label="MAP"
                    value={variation?.map ?? ''}
                    onChange={(e) =>
                      onChange(index, 'map', e.currentTarget.value)
                    }
                  />
                </TableCell>
              );
            } else if (column.keyName === 'msrp') {
              return (
                <TableCell
                  key={column.keyName}
                  style={{ borderBottom: 'none' }}
                >
                  <TextField
                    style={{
                      width: '100%',
                      backgroundColor: primary.transparent.main,
                    }}
                    variant="outlined"
                    label="MSRP"
                    value={variation?.msrp ?? ''}
                    onChange={(e) =>
                      onChange(index, 'msrp', e.currentTarget.value)
                    }
                  />
                </TableCell>
              );
            } else if (column.keyName === 'itemPrice') {
              return (
                <TableCell
                  key={column.keyName}
                  style={{ borderBottom: 'none' }}
                >
                  <TextField
                    style={{
                      width: '100%',
                      backgroundColor: primary.transparent.main,
                    }}
                    variant="outlined"
                    required
                    label="Price"
                    onChange={(e) =>
                      onChange(index, 'itemPrice', e.currentTarget.value)
                    }
                  />
                </TableCell>
              );
            } else if (column.keyName === 'supfit') {
              return (
                <TableCell
                  key={column.keyName}
                  style={{ borderBottom: 'none' }}
                >
                  <CancelTwoToneIcon
                    onClick={() => removeVariation(index)}
                    fontSize="large"
                    style={{ color: '#FC0301' }}
                  />
                </TableCell>
              );
            }
          })}
        </TableRow>
      </Table>
      <Divider />
      <Grid item container xs={12} spacing={2} style={{ marginTop: 20 }}>
        <Grid item xs={12}>
          {fitment[index] &&
            fitment[index].map((fit, i) => {
              return (
                <Grid
                  key={i}
                  item
                  container
                  xs={12}
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                  style={{ marginTop: 10 }}
                >
                  <Grid item xs={12} style={{ maxWidth: '560px' }}>
                    <InputLabel id="year">Years</InputLabel>
                    <Select
                      style={{ width: '100%', minWidth: 488, maxHeight: 38 }}
                      variant="outlined"
                      value={fit.years}
                      multiple
                      label="Year"
                      onChange={(e: any) => {
                        updateFitment(index, i, 'years', e.target.value);
                      }}
                    >
                      {years().map((year) => {
                        return (
                          <MenuItem key={year} value={year.toString()}>
                            {year}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  <Grid item xs={3} style={{ width: '440px' }}>
                    <InputLabel id="year">Make</InputLabel>
                    <Select
                      style={{ width: '100%', maxHeight: 38 }}
                      variant="outlined"
                      value={fit.make}
                      label="Make"
                      placeholder="Select Year(s)"
                      disabled={fit?.years?.length === 0}
                      onChange={(e: any) => {
                        updateFitment(index, i, 'make', e.target.value);
                      }}
                    >
                      {filterMakes(makes, fit.years).map(({ make, id }) => {
                        return (
                          <MenuItem key={id} value={id}>
                            {make}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  <Grid item xs={3} style={{ width: '440px' }}>
                    <InputLabel id="year">Model</InputLabel>
                    <AsyncSelect
                      placeholder="Search..."
                      id="model"
                      loadOptions={async (inputValue) =>
                        filterModels(inputValue, models, fit)
                      }
                      value={fit.model}
                      onChange={(selectedOption) => {
                        updateFitment(index, i, 'model', selectedOption);
                      }}
                      getOptionLabel={(option) => option.model}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    justifyContent="center"
                    alignItems="flex-end"
                    style={{ marginTop: 10 }}
                  >
                    <CustomColorIconButton
                      size="medium"
                      onClick={() => deleteRow(index, i)}
                    >
                      <FontAwesome name="times-circle" type="fa" form="far" />
                    </CustomColorIconButton>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VariationBox;
