import { useState, FormEvent, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { pick } from 'lodash';
import {
  Grid,
  Typography,
  Button,
  InputAdornment,
  FormControl,
  IconButton,
  OutlinedInput,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import useQuery from '@src/hooks/util/useQuery';
import { TextField, Backdrop } from '@src/Components/common';
import type { FormProps } from '@src/Components/common/containers/SearchView/CreationDrawer/types';
import FontAwesome from '@src/Components/common/FontAwesome';
import queryUtils from '@src/utils/queries';
import BrandApi from '@oneAppCore/services/BrandApi';
import type { BrandForEdit } from '@oneAppCore/types/brandTypes';
import { SupplierForm } from './types';
import SupplierSelect from '@src/Components/common/Selects/SupplierSelect';
import { formFields, complexFormFields } from './constants';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    paddingTop: theme.spacing(1),
    width: '100%',
  },
  selectStyle: {
    height: '43px',
    '& .MuiSelect-select:focus': {
      backgroundColor: 'transparent !important',
    },
  },
}));

function BrandDrawer({ onSubmit, close }: FormProps) {
  const classes = useStyles();

  const [form, setForm] = useState<BrandForEdit>({ isMap: false });
  const [brandArray, setBrandArray] = useState([]);
  const [discountArray, setDiscountArray] = useState([]);
  const [suppliers, setSuppliers] = useState<SupplierForm[]>([]);
  const [saving, setSaving] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { id } = useQuery();
  const history = useHistory();

  const handleSelectValue = (value, index) => {
    let updatedDiscountArray = [...discountArray]
    updatedDiscountArray[index]['discountPercent'] = value == '%' ? true : false
    updatedDiscountArray[index]['discountFlat'] = value == '$' ? true : false
    setDiscountArray(updatedDiscountArray.filter(element => element))
  };

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await BrandApi.getById(Number(id));
        const newForm = pick(response, formFields);
        setForm(newForm);
        setBrandArray(newForm.brandAliases);
        setDiscountArray(newForm.discounts);
      })();
    } else {
      setForm({});
    }
  }, [id]);

  const update = <K extends keyof BrandForEdit>(
    key: K,
    value: BrandForEdit[K],
  ) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const updateItem = (index: number, value: any, key: string) => {
    const newList = form[key];
    newList[index] = value;
    setForm({
      ...form,
      [key]: newList,
    });
  };

  const updateDiscount = (index: number, key: any, value: any) => {
    const updatedDisc = discountArray.slice();
    updatedDisc[index] = {
      ...updatedDisc[index],
      [key]: typeof value === 'string' ? parseInt(value, 10) : value,
    };
    update('discounts', updatedDisc);
    setDiscountArray(updatedDisc);
  };

  const removeDiscount = (index: number) => {
    const updatedDisc = discountArray.slice();
    updatedDisc.splice(index, 1);
    update('discounts', updatedDisc);
    setDiscountArray(updatedDisc);
  };

  const addItem = (key: string) => {
    const newList = form[key] ? form[key] : [];
    newList.push('');
    setForm({
      ...form,
      [key]: newList,
    });
  };

  const addDiscount = () => {
    const updatedDiscounts = discountArray.slice();
    updatedDiscounts.push({ supplierId: 0, discountPercent: false, discountFlat: true });
    update('discounts', updatedDiscounts);
    setDiscountArray(updatedDiscounts);
  };

  const updateDiscountType = (index: number, type: string) => {
    const updatedDiscounts = discountArray.slice();
    const discountPercent = type === 'perc';
    const discountFlat = type === 'flat';
    updatedDiscounts[index] = { ...updatedDiscounts[index], discountFlat, discountPercent };
    update('discounts', updatedDiscounts);
    setDiscountArray(updatedDiscounts);
  };

  const updateSuppliers = <K extends keyof SupplierForm>(
    index: number,
    key: K,
    value: SupplierForm[K],
  ) => {
    const updatedSuppliers = suppliers.slice();
    updatedSuppliers[index] = {
      ...updatedSuppliers[index],
      [key]: value,
    };
    setSuppliers(updatedSuppliers);
  };

  const removeItem = (key: string, index: number) => {
    const newList = form[key];
    newList.splice(index, 1);
    setForm({
      ...form,
      [key]: newList,
    });
  };

  const submit = async (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setSaving(true);
    try {
      const newForm = form;
      let operation = 'Created';
      complexFormFields
        .filter((field) => field.type === 'list')
        .forEach((field) => {
          if (newForm[field.name]) {
            newForm[field.name] = newForm[field.name].filter((value) => value);
          }
        });
      if (id) {
        operation = 'Updated';
        await BrandApi.put(Number(id), newForm);
        const newParams = queryUtils.remove('id');
        history.replace({
          search: newParams,
        });
      } else {
        await BrandApi.post(newForm);
      }
      enqueueSnackbar(`Brand ${operation}`, {
        variant: 'success',
      });
      await onSubmit();
    } catch (e) {
      enqueueSnackbar(`${e.message || e}`, {
        variant: 'error',
      });
    } finally {
      setSaving(false);
    }
  };

  const typeLogicCheck = (percent = false, flat = false) => {
    let retVal = 'perc';
    if ((!percent && !flat) || (percent && flat)) {
      retVal = 'perc'
    } else if (flat) {
      retVal = 'flat'
    } else {
      retVal = 'perc'
    };
    return typeList.find((item) => item.value === retVal) || retVal;
  }

  const typeList = [
    { label: 'Flat', value: 'flat' },
    { label: 'Percent', value: 'perc' },
  ]

  const renderTextInput = (
    propertyName: any,
    propertyLabel: string,
    variant: 'outlined' | 'filled' | 'standard' = 'outlined',
  ) => {
    return (
      <TextField
        fullWidth
        variant={variant}
        label={propertyLabel}
        name={propertyName}
        value={form?.[propertyName] || ''}
        onChange={(e) => update(propertyName, e.target.value)}
        required
      />
    );
  };

  const renderListInput = (
    propertyName: any,
    propertyLabel: string,
    variant: 'outlined' | 'filled' | 'standard' = 'outlined',
  ) => {
    const listThing = form?.[propertyName] ? form?.[propertyName] : brandArray;
    return (
      <>
        <Grid item container>
          <Typography variant="h5">{propertyLabel}</Typography>
        </Grid>
        <Grid container direction="column">
          {listThing?.map((listItem, index) => {
            return (
              <FormControl key={`brand-${propertyName}-${index + 1}`}>
                <OutlinedInput
                  style={{ margin: 5 }}
                  fullWidth
                  id={`brand-${propertyName}-${index + 1}`}
                  type="text"
                  name={`${propertyName}-${index}`}
                  value={listItem || ''}
                  onChange={(e) =>
                    updateItem(index, e.target.value, propertyName)
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        color="primary"
                        aria-label={`remove item from ${propertyLabel} list`}
                        onClick={() => {
                          removeItem(propertyName, index);
                        }}
                      >
                        <FontAwesome
                          size="small"
                          name="times"
                          type="fa"
                          form="fas"
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            );
          })}
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              addItem(propertyName);
            }}
            style={{ margin: 5 }}
          >
            Add Alias
          </Button>
        </Grid>
      </>
    );
  };

  const inputTypes = {
    text: renderTextInput,
    list: renderListInput,
  };

  return (
    <form className={classes.form} onSubmit={submit}>
      <Grid container spacing={2} direction="column" className={classes.form}>
        <Grid item container justifyContent="center">
          <Typography variant="h4">Brand Drawer</Typography>
        </Grid>
        {complexFormFields.map((field, index) => (
          <Grid item key={`edit-brand-${index}`}>
            {inputTypes[field.type](field.name, field.label)}
          </Grid>
        ))}
        <Grid container xs={12} justifyContent="space-between">
          <Grid item container>
            <Typography variant="h5">MAP</Typography>
          </Grid>
          <Grid container xs={12} justifyContent="space-between">
            <FormControlLabel
              control={
                <Checkbox
                  title="Signature Required"
                  checked={form.isMap}
                  onChange={(e) => {
                    update('isMap', e.target.checked);
                  }}
                />
              }
              label="Brand uses MAP"
            />
          </Grid>
        </Grid>
        <Grid container xs={12} justifyContent="space-between">
          <Grid item container>
            <Typography variant="h5">Discounts</Typography>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-start">
            <Typography variant="h6">Supplier</Typography>
          </Grid>
          <Grid item container xs={3} justifyContent="flex-start">
            <Typography variant="h6" style={{ paddingLeft: '5px' }}>
              Amount
            </Typography>
          </Grid>
          <Grid item container xs={2} justifyContent="flex-start">
            <Typography variant="h6">Type</Typography>
          </Grid>
          <Grid item container xs={1} justifyContent="flex-start"></Grid>
          {discountArray
            ?.filter((item) => item)
            .map(
              (
                { discountAmount, supplierId, discountPercent, discountFlat },
                index,
              ) => {
                return (
                  <Grid
                    container
                    xs={12}
                    justifyContent="space-between"
                    key={index + 1}
                  >
                    <Grid
                      item
                      container
                      xs={6}
                      justifyContent="flex-start"
                      style={{ marginTop: 5 }}
                    >
                      <SupplierSelect
                        label={''}
                        value={supplierId}
                        onChange={(e) =>
                          updateDiscount(index, 'supplierId', e.value)
                        }
                      />
                    </Grid>
                    {/* <Grid
                  item
                  container xs={2}
                  style={{ marginTop: 5 }}
                >
                  <AppSelect
                    label={''}
                    options={typeList}
                    onChange={(e) => { updateDiscountType(index, e.value) }}
                    value={typeLogicCheck(discountPercent, discountFlat)}
                  />
                </Grid> */}
                <Grid item container xs={3}>
                  <OutlinedInput
                    id={`discount ${index + 1}`}
                    name={`discount ${index}`}
                    style={{ margin: '5px', width: '95%', paddingRight: '10px' }}
                    value={discountAmount}
                    onChange={(e) =>
                      updateDiscount(index, 'discountAmount', e.target.value)
                    }
                    type='number'
                  />
                </Grid>
                <Grid item container xs={2} style={{ marginTop: 3 }}>
                  <Select
                    labelId={`discField ${index + 1}-label`}
                    id={`discField ${index + 1}`}
                    value={discountPercent == true ? '%' : '$'}
                    variant={'outlined'}
                    onChange={(e: any) =>
                      handleSelectValue(e?.target?.value, index)
                    }
                    className={classes.selectStyle}
                  >
                    <MenuItem key={'$'} value={'$'}>
                      $
                    </MenuItem>
                    <MenuItem key={'%'} value={'%'}>
                      %
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item container xs={1}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      removeDiscount(index);
                    }}
                    style={{ paddingLeft: '0', paddingRight: '0' }}
                  >
                    <FontAwesome
                      size="small"
                      name="times"
                      type="fa"
                      form="fas"
                    />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
          <Grid item container xs={12}>
            <Button
              variant="outlined"
              color="primary"
              style={{ margin: 5 }}
              onClick={(e) => {
                addDiscount();
              }}
            >
              Add Supplier
            </Button>
          </Grid>
          {suppliers.map((suppliers: SupplierForm, index: number) => (
            <>
              <Grid
                item
                container
                xs={8}
                key={`supplier-${index + 1}`}
                style={{ marginTop: '5px' }}
              >
                <SupplierSelect
                  label={''}
                  value={suppliers.name ?? ''}
                  style={{ width: '95%' }}
                />
              </Grid>
              <Grid item container xs={4}>
                <FormControl>
                  <OutlinedInput
                    style={{
                      width: '95%',
                      margin: '5px',
                    }}
                    onChange={(e) =>
                      updateDiscount(
                        index,
                        'discountAmount',
                        e.currentTarget.value,
                      )
                    }
                    type="text"
                    value={''}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          color="primary"
                          aria-label={`remove item from discount list`}
                          onClick={() => {
                            removeDiscount(index);
                          }}
                        >
                          <FontAwesome
                            size="small"
                            name="times"
                            type="fa"
                            form="fas"
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
            </>
          ))}
        </Grid>
        <Grid item container justifyContent="space-around">
          <Grid item xs={5}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={close}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop open={saving} />
    </form>
  );
}

export default BrandDrawer;
