import type { MiniReportPayload } from './hooks/useSearch/types';
import type { FormProps } from './CreationDrawer/types';
import type { DrawerProps } from '@src/Components/Orders/OrderDrawer/types';
export type FilterType =
  | 'date range'
  | 'date range select'
  | 'equality'
  | 'string'
  | 'date'
  | 'selector'
  | 'array'
  | 'multiSelector'
  | 'fitment'
  | 'number'
  | 'text';

export type FilterOptions = {
  hideDefaultNoneOption?: boolean;
  multiSelect?: boolean;
};

export type FilterProperties = {
  label: string;
  value: any;
  disabled?: boolean;
  default?: boolean;
};

export type Filters = {
  type: FilterType;
  name: string;
  keyName: string;
  disabled?: boolean;
  operator?: string;
  properties?: FilterProperties[];
  options?: FilterOptions;
  defaultValue?: string;
};

export type ConstantFilters = {
  [key: string]: string | number | Array<string | number>;
};

export type MiniReports = (props: MiniReportPayload) => JSX.Element;
export type OrderViewDrawer = () => JSX.Element;

export type ColumnType = 'number' | 'string' | 'date' | 'parent' | 'money';

export type ColumnAlign = 'center' | 'left' | 'right';

export type Column = {
  title: string;
  editable?: boolean;
  editHandler?: (
    row,
    newRow,
    isEditMode,
    setEditMode,
    index,
    editCell,
    setEditCell,
    mutate,
  ) => JSX.Element;
  customTitle?: (row: any) => JSX.Element;
  // customTitle?: any;
  customComponent?: (
    row: any,
    setOpenCell?: any,
    activeCell?: any,
    openedPackCell?: any,
    setOpenedPackCell?: any,
    setImageCell?: any,
    imageCell?: any,
    cellIndex?: number,
    mutate?: any,
  ) => JSX.Element;
  sortBy?: boolean;
  type?: ColumnType;
  align?: ColumnAlign;
  justify?: ColumnAlign;
  keyName: string;
  styles?: any;
  hidden?: boolean;
  width?: number;
  minWidth?: number;
};
export type ChildColumn = Column & {
  parentKeyName: string;
};

export type ChannelOptions =
  | 'amazon'
  | 'wooCommerce'
  | 'walmart'
  | 'ebay'
  | 'shopify';

export const channelEnums = {
  WOO_COMMERCE: 'wooCommerce',
  AMAZON: 'amazon',
  WALMART: 'walmart',
  EBAY: 'ebay',
  SHOPIFY: 'shopify',
};

export type ExportOption = {
  label: string;
  value: string;
  supportedChannels: ChannelOptions[];
  url: string;
  query?: any;
};

export type SelectedRow = {
  label: string;
  value: any;
  id: number;
  customComponent?: (
    row: any,
    setOpenCell?: any,
    activeCell?: any,
    setImageCell?: any,
    imageCell?: any,
    cellIndex?: number,
    mutate?: any,
  ) => JSX.Element;
};

export type AllowedSelectTypes =
  | 'batch-print'
  | 'download'
  | 'approve'
  | 'create'
  | 'createSChannel'
  | 'createProduct'
  | 'bulkExport'
  | 'bulkPush'
  | 'createCompany'
  | 'createInventory'
  | 'createBrand'
  | 'createSupplier'
  | 'export'
  | 'restock';

export type Props = {
  url?: string;
  showChannelOptions?: boolean;
  showInventoryOptions?: boolean;
  supportsDownload?: boolean;
  MiniReportComponent?: MiniReports;
  exportOptions?: ExportOption[];
  selectedRows?: SelectedRow[];
  CreateFormComponent?: (props: any) => JSX.Element;
  // CreateFormComponent?: (props: FormProps) => JSX.Element;
  filters?: Filters[];
  constantFilters?: ConstantFilters;
  columns?: Column[];
  childColumns?: ChildColumn[];
  ViewDrawerComponent?: (props: DrawerProps) => JSX.Element;
  select?: boolean;
  selectTypes?: AllowedSelectTypes[];
  carrierData?: any[];
  orderBy?: [string, 'asc' | 'desc'];
  mutateValue?: number;
  me?: any;
  swrOptions?: any;
  customSearchBarComponents?: any;
  togglePageOptions?: string[];
  selectedTogglePage?: string;
  setSelectedTogglePage?: (options: any) => void;
};
