import {
  DataGridPremium,
  GridColDef,
  GridCellParams,
  GridRowId,
  GridValidRowModel,
  DataGridPremiumProps,
  useGridApiRef,
  GridRowModel,
  GridToolbarColumnsButton,
  gridClasses,
} from '@mui/x-data-grid-premium';
import { LicenseInfo } from '@mui/x-license';
import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { reposState as reposAtom } from './atoms';
import {
  CircularProgress,
  Grid,
  Paper,
  Box,
  Checkbox,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import SearchPaginator from './SearchPaginator';
import SearchBar from './SearchBar';
import { Props } from './types';
import { Column } from '../types';
import ChannelCategorySelect from '@src/Components/common/Selects/ChannelCategorySelect';
import { useSnackbar } from 'notistack';

LicenseInfo.setLicenseKey(
  'c89907e86a6c9fe18b2d2e6f28a22aaeTz05NjY2MCxFPTE3NTU4NzU5NDgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=',
);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    margin: theme.spacing(3, 0),
  },
  header: {
    backgroundColor: '#f3f2f7',
    color: theme.palette.grey[800],
    fontSize: '1rem',
    fontWeight: 400,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    whiteSpace: 'nowrap',
    // minWidth: '100%',
  },
  cell: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  editedRow: {
    backgroundColor: theme.palette.warning.light,
  },
}));

function mapColumnsToGridColDef(columns: Column[]): GridColDef[] {
  return columns.map((column) => {
    const colDef: GridColDef = {
      field: column.keyName,
      headerName: column.title,
      editable: column.editable,
      sortable: column.sortBy,
      align: column.align,
      headerAlign: column.justify,
      minWidth: column.width || 15,
      flex: 1,
    };

    if (column.customComponent) {
      colDef.renderCell = (params) => column.customComponent!(params.row);
    }

    if (column.type) {
      switch (column.type) {
        case 'string':
          colDef.type = 'string';
          break;
        case 'number':
          colDef.type = 'number';
          break;
        case 'date':
          colDef.type = 'date';
          colDef.valueGetter = (params: GridCellParams) => {
            const value = params?.row?.[column?.keyName];
            return value ? new Date(value) : null;
          };
          break;
        default:
          break;
      }
    }

    return colDef;
  });
}

interface RowType {
  [key: string]: any;
}

interface ChannelCategoryResponse {
  id: number;
  categoryName: string;
}

interface ChannelCategorySelectProps {
  value: number;
  onChange: (id: number, category: ChannelCategoryResponse) => void;
  updateUnsavedChangesRef: (
    id: number,
    category: ChannelCategoryResponse,
  ) => void;
}

function GridTable({
  columns,
  filters,
  supportsDownload,
  constantFilters,
  exportOptions,
  showChannelOptions,
  select,
  selectTypes,
  url,
  carrierData,
  searchData,
  gridMutate,
  swrOptions = {
    revalidateOnFocus: true,
    focusThrottleInterval: 3500,
  },
  me,
  customSearchBarComponents,
  selectedFilterValues,
  setSelectedFilterValues,
  editableColumns,
  api,
}: Props) {
  const classes = useStyles();
  const [resetPagination, setResetPagination] = useState(false);
 /*  const { data, mutate } = useSearch(
    swrOptions.revalidateOnFocus,
    false,
    swrOptions.focusThrottleInterval,
  ); */
  const [rowState, setRowState] = useState<Map<GridRowId, any>>(new Map());
  const [hasUnsavedRows, setHasUnsavedRows] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [modifiedRows, setModifiedRows] = useState<Map<GridRowId, any>>(
    new Map(),
  );
  const [selectedTableRows, setSelectedTableRows] = useRecoilState(reposAtom);
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [copiedValue, setCopiedValue] = useState<string | number | null>(null);
  const [selectedCellIds, setSelectedCellIds] = useState<GridRowId[]>([]);
  const [changedRows, setChangedRows] = useState<GridRowModel[]>([]);
  const [clickedCell, setClickedCell] = useState<{
    id: any;
    field: string;
  } | null>(null);
  const unsavedChangesRef = React.useRef<{
    unsavedRows: Record<GridRowId, GridValidRowModel>;
    rowsBeforeChange: Record<GridRowId, GridValidRowModel>;
  }>({
    unsavedRows: {},
    rowsBeforeChange: {},
  });
  const { enqueueSnackbar } = useSnackbar();

  const apiRef = useGridApiRef();

  const handleCellClickAgain = (params: GridCellParams) => {
    setSelectedCellIds([params.id]);
  };
  const handleSelectionModelChange = (newSelection: GridRowId[]) => {
    setSelectedCellIds(newSelection);
  };

  useEffect(() => {
    if (searchData?.rows) {
      const rowMap = new Map();
      searchData.rows.forEach((row) => rowMap.set(row.id, row));
      setRowState(rowMap);
    } else {
      console.error('No rows found in data');
    }
  }, [searchData]);

  const handleRowEditCommit = (params: GridCellParams) => {
    const rowId = params.id as GridRowId;
    const newRow = params.row;
    const oldRow = rowState.get(rowId);

    if (JSON.stringify(newRow) !== JSON.stringify(oldRow)) {
      setModifiedRows((prevModifiedRows) => {
        const updatedModifiedRows = new Map(prevModifiedRows);
        updatedModifiedRows.set(rowId, newRow);
        return updatedModifiedRows;
      });
      setHasUnsavedRows(true);
    }
  };

  const handleCopy = () => {
    if (selectedCellIds.length === 1) {
      const selectedCell = rowState.get(selectedCellIds[0]);
      if (selectedCell) {
        setCopiedValue['name'];
      }
    }
  };

  const handlePaste = (value: string | number | null) => {
    if (value !== null && selectedCellIds.length > 0) {
      setRowState((prevRowState) => {
        const updatedRows: [any, any][] = Array.from(
          prevRowState.entries(),
        ).map(([id, row]) => {
          if (selectedCellIds.includes(id)) {
            return [id, { ...row, [clickedCell.field]: value }];
          }
          return [id, row];
        });
        return new Map(updatedRows);
      });
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'c') {
        handleCopy();
      }
      if (event.ctrlKey && event.key === 'v') {
        handlePaste(copiedValue);
      }
      if (event.shiftKey && event.key === 'ArrowDown') {
        handleShiftArrowDown();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedCellIds, copiedValue]);

  const handleShiftArrowDown = () => {
    if (selectedCellIds.length > 0) {
      const lastSelectedId = selectedCellIds[selectedCellIds.length - 1];
      const allRowIds = Array.from(rowState.keys());
      const currentIndex = allRowIds.indexOf(lastSelectedId);

      if (currentIndex !== -1 && currentIndex < allRowIds.length - 1) {
        const nextId = allRowIds[currentIndex + 1];
        if (!selectedCellIds.includes(nextId)) {
          setSelectedCellIds([...selectedCellIds, nextId]);
        }
      }
    }
  };

  const handleCheckboxChange = (id: GridRowId) => {
    const isSelected = selectedRowIds.includes(id);
    if (isSelected) {
      setSelectedRowIds((prev) => prev.filter((rowId) => rowId !== id));
      setSelectedTableRows((prev) =>
        prev.filter((selectedRow) => selectedRow.id !== id),
      );
    } else {
      setSelectedRowIds((prev) => [...prev, id]);
      setSelectedTableRows((prev) => [...prev, rowState.get(id)]);
    }
  };
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedRowIds([]);
      setSelectedTableRows([]);
    } else {
      const allRowIds = Array.from(rowState.keys());
      setSelectedRowIds(allRowIds);
      setSelectedTableRows(Array.from(rowState.values()));
    }
    setSelectAll(!selectAll);
  };
  /* 
  useEffect(() => {
    if (searchData?.rows) {
      const rowMap = new Map();
      searchData.rows.forEach((row) => rowMap.set(row.id, row));
      setRowState(rowMap);
    } else {
      console.error('No rows found in data');
    }
  }, [searchData]); 
  */
  const gridColumns: GridColDef[] = [
    {
      field: 'checkbox',
      headerName: '',
      renderHeader: () => (
        <Checkbox checked={selectAll} onChange={handleSelectAllChange} />
      ),
      width: 110,
      renderCell: (params) => (
        <Checkbox
          checked={selectedRowIds.includes(params.id)}
          onChange={() => handleCheckboxChange(params.id)}
        />
      ),
    },
    ...mapColumnsToGridColDef(columns),
  ];
  const handleCellClick = (params: GridCellParams) => {
    if (params.field === 'categories') {
      setClickedCell({ id: params.id, field: params.field });
    } else {
      setClickedCell(null);
    }
  };

  const handleRowUpdate = (updatedRow: GridRowModel) => {
    setChangedRows((prev) => {
      const originalRow =
        prev.find((row) => row.id === updatedRow.id) ||
        apiRef.current.getRow(updatedRow.id);

      if (!prev.some((row) => row.id === updatedRow.id)) {
        return [...prev, originalRow];
      }

      return prev.map((row) => (row.id === updatedRow.id ? updatedRow : row));
    });

    apiRef.current.updateRows([updatedRow]);
  };

  const renderCell = (params: GridCellParams) => {
    if (clickedCell?.id === params.id && clickedCell.field === params.field) {
      const handleChange = (id: number, categoryName: string) => {
        const originalRow = { ...params.row };

        if (!unsavedChangesRef.current.rowsBeforeChange[params.id]) {
          unsavedChangesRef.current.rowsBeforeChange[params.id] = originalRow;
        }

        const updatedRow = {
          ...params.row,
          channelCategoryId: id,
          category: categoryName,
        };

        unsavedChangesRef.current.unsavedRows[params.id] = updatedRow;

        handleRowUpdate(updatedRow);
        setHasUnsavedRows(true);
        setClickedCell(null);
      };
      return (
        <ChannelCategorySelect
          value={params.value as number}
          onChange={handleChange}
        />
      );
    }

    return (
      <span>
        {params.row.categories === '' && params.row.category === null
          ? 'No Category Associated'
          : params.row.category || params.row.categories}
      </span>
    );
  };

  const modifiedColumns = gridColumns.map((col) => {
    if (col.field === 'categories') {
      return { ...col, renderCell };
    }
    return col;
  });

  const processRowUpdate = React.useCallback<
    NonNullable<DataGridPremiumProps['processRowUpdate']>
  >((newRow, oldRow) => {
    const rowId = newRow.id;

    unsavedChangesRef.current.unsavedRows[rowId] = newRow;
    if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
      unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
    }
    setHasUnsavedRows(true);
    return newRow;
  }, []);

  const getRowClassName = React.useCallback<
    NonNullable<DataGridPremiumProps['getRowClassName']>
  >(
    ({ id }) => {
      const unsavedRow = unsavedChangesRef.current.unsavedRows[id];
      if (unsavedRow) {
        if (unsavedRow._action === 'delete') {
          return 'row--removed';
        }
        return 'row--edited';
      }
      return '';
    },
    [classes.editedRow],
  );

  const discardChanges = React.useCallback(() => {
    setHasUnsavedRows(false);
    apiRef.current.updateRows(
      Object.values(unsavedChangesRef.current.rowsBeforeChange),
    );
    unsavedChangesRef.current = {
      unsavedRows: {},
      rowsBeforeChange: {},
    };
  }, [apiRef]);


  //   const updateObject = {};
  // Object.keys(unsavedChangesRef.current.unsavedRows).forEach((rowId) => {
  //   const rowData = unsavedChangesRef.current.unsavedRows[rowId];
  //   updateObject[rowId] = { ...rowData };
  // });

  const saveChanges = async () => {
    setIsSaving(true);
    setHasUnsavedRows(false);
    const updateObject = {};
    const unsavedRows = unsavedChangesRef.current.unsavedRows;
    for (const id in unsavedRows) {
      const filteredRow = {};
      editableColumns?.forEach?.((col) => {
        if (unsavedRows?.[id]?.hasOwnProperty(col)) {
          filteredRow[col] = unsavedRows?.[id][col];
        }
      });
      updateObject[id] = filteredRow;
    }
    try {
      const response: any = await api.bulkUpdate({
        updateObj: updateObject,
      });
      if (response.success) {
        enqueueSnackbar(`Successfully updated data!`, {
          variant: 'success',
        });
        gridMutate().then(() => {
          unsavedChangesRef.current = {
            unsavedRows: {},
            rowsBeforeChange: {},
          };
        });
      } else {
        setHasUnsavedRows(true);
        enqueueSnackbar(`Error updating data`, {
          variant: 'error',
        });
      }
    } catch (error) {
      enqueueSnackbar(`Error updating data`, {
        variant: 'error',
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <SearchPaginator
        showChannelOptions={showChannelOptions}
        swrOptions={swrOptions}
        resetPagination={resetPagination}
        setResetPagination={setResetPagination}
        siblingCount={1}
        boundaryCount={2}
      />
      <Paper className={classes.root}>
        <Grid container item xs={12}>
          <SearchBar
            customComponents={customSearchBarComponents}
            hasUnsavedRows={hasUnsavedRows}
            saveChanges={saveChanges}
            undo={discardChanges}
            filters={filters}
            constantFilters={constantFilters}
            supportsDownload={supportsDownload}
            showChannelOptions={showChannelOptions}
            exportOptions={exportOptions}
            selectedRows={selectedTableRows}
            select={select}
            selectTypes={selectTypes}
            deselectAllRows={() => setSelectedTableRows([])}
            carrierData={carrierData}
            me={me}
            resetPagination={resetPagination}
            setResetPagination={setResetPagination}
            selectedFilterValues={selectedFilterValues}
            setSelectedFilterValues={setSelectedFilterValues}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ overflowX: 'scroll', overflowY: 'auto', maxHeight: 600 }}
        >
          {searchData ? (
            <Box style={{ width: '100%' }}>
              <DataGridPremium
                classes={{ columnHeader: classes.header, cell: classes.cell }}
                onCellClick={handleCellClick}
                {...searchData}
                columns={modifiedColumns}
                autosizeOnMount={true}
                apiRef={apiRef}
                disableRowSelectionOnClick
                cellSelection
                density="comfortable"
                processRowUpdate={processRowUpdate}
                ignoreValueFormatterDuringExport
                loading={isSaving}
                getRowClassName={getRowClassName}
                hideFooterPagination
                hideFooter
                slots={{ toolbar: GridToolbarColumnsButton }}
                autoHeight
                sx={{
                  width: '100%',
                  display: 'flex',
                  '& .MuiDataGrid-row': {
                    backgroundColor: '#000000',
                  },
                  '& .MuiDataGrid-row:nth-of-type(odd)': {
                    backgroundColor: 'grey.100',
                  },
                  '& .MuiDataGrid-row:nth-of-type(even)': {
                    backgroundColor: 'white',
                  },
                  '& .MuiDataGrid-columnHeader': {
                    position: 'sticky',
                    top: 0,
                    zindex: 1,
                  },
                  '& .css-1vnnnbi-MuiButtonBase-root-MuiButton-root': {
                    justifyContent: 'flex-start',
                    marginLeft: 2,
                  },
                  [`& .${gridClasses.row}.row--removed`]: {
                    backgroundColor: (theme) => {
                      if (theme.palette.mode === 'light') {
                        return 'rgba(255, 170, 170, 0.3)';
                      }
                      return 'rgba(255, 170, 170, 1)';
                    },
                  },
                  [`& .${gridClasses.row}.row--edited`]: {
                    backgroundColor: (theme) => {
                      if (theme.palette.mode === 'light') {
                        return 'rgba(255, 254, 176, 0.3)';
                      }
                      return 'rgba(255, 254, 176, 1)';
                    },
                  },
                }}
              />
            </Box>
          ) : (
            <Grid
              item
              container
              xs={12}
              justifyContent="center"
              style={{
                marginTop: 20,
                marginBottom: 20,
                justifyContent: 'center',
              }}
            >
              <CircularProgress color="primary" />
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  );
}

export default GridTable;
