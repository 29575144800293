import {
  formatOptions as newFormatOptions,
  oldFormatOptions,
} from 'types/currencyTypes';

export function formatCentsToDollars(
  input: number | string,
  options: oldFormatOptions = {},
) {
  if (!input) return '0';
  const inputString = typeof input !== 'string' ? `${Math.abs(input)}` : input;
  const isNegative = Number(input) < 0;

  const cents = inputString.slice(-2);
  const dollarBase = inputString.slice(0, -2);
  const dollars = dollarBase || '0';
  let ret;
  if (options.ignoreCommas) {
    ret = `${dollars}.${cents}`;
  } else {
    if (options.currency) {
      ret = parseFloat(`${dollars}.${cents}`).toLocaleString(undefined, {
        style: 'currency',
        currency: options.currency,
      });
    } else if (options.removeMoneySign) {
      ret = parseFloat(`${dollars}.${cents}`).toFixed(2);
    } else {
      ret = parseFloat(`${dollars}.${cents}`).toLocaleString(undefined, {
        style: 'currency',
        currency: 'USD',
      });
    }
  }

  // Add the negative sign if the original input was negative
  if (isNegative) {
    ret = `-${ret}`;
  }

  return ret;
}

export function formatCentsToDollarsIntl(
  input: number | string,
  options: newFormatOptions = {},
) {
  if (!input) return '0';
  const inputString = typeof input !== 'string' ? `${input}` : input;
  const {
    useGrouping = true,
    locale = 'en-US',
    currency = 'USD',
    removeMoneySign = false,
  } = options;

  const cents = inputString.slice(-2);
  const dollarBase = inputString.slice(0, -2);
  const dollars = dollarBase;
  let ret;
  const dcs: any = `${dollars}.${cents}`;
  let formatOptions: Intl.NumberFormatOptions = { useGrouping, currency, style: 'currency' };
  if (removeMoneySign) delete formatOptions.style;
  const format = Intl.NumberFormat(locale, formatOptions);
  ret = format.format(dcs);
  // if (localeOptions) {
  //   ret = parseFloat(`${dollars}.${cents}`).toLocaleString(locale || undefined, localeOptions)
  // } else {
  //   ret = parseFloat(`${dollars}.${cents}`).toLocaleString(locale || undefined, { style:'currency', currency: 'USD' })
  // }

  return ret;
}

export function convertDollarsToCents(input: string | number): number {
  if (!input) return 0;
  const inputString = typeof input !== 'string' ? `${input}` : input;
  const hasMinus = inputString.includes('-');
  const inputStringSplit = inputString.split('.');

  const centString1 = inputStringSplit.length > 1 ? inputStringSplit[1] : '0';
  const centString =
    centString1.length > 2 ? centString1.slice(0, 2) : centString1;
  const dollarString = hasMinus
    ? inputStringSplit[0].replace('-', '')
    : inputStringSplit[0];

  const cents = parseInt(centString);
  const dollars = parseInt(dollarString || '0') * 100;
  let output = dollars + (centString.length === 1 ? cents * 10 : cents);
  return hasMinus ? output * -1 : output;
}

export function checkIfCents(input: string | number) {
  const inputString = typeof input !== 'string' ? `${input}` : input;
  return !inputString.includes('.');
}

export function suggestProfitMultiplier(percentage: string | number = 3, revPerc: number = .88, addPerc: boolean = false) {
  const percType = typeof percentage === 'string';
  const stp = percentage as string;
  const nmp = `${percentage}` as string;
  let percent: string = percType ? stp : nmp;
  if (parseFloat(percent) > 99.0) throw new Error('Number is too large!');
  const hasDec = percent.includes('.');
  const percLength = hasDec ? percent.split('.')[0].length : percent.length;
  const percString = percLength > 1 ? `1.${percent.replace('.', '')}` : `1.0${percent.replace('.', '')}`;
  const add = addPerc ? parseFloat(percent) % 10 : 0;
  const perc = (Math.ceil((parseFloat(percString) / revPerc) * 1000) + add) / 1000;
  return perc;
}

export function calculateProfit(
  price: number,
  cost: number,
  percProp: string = 'cost',
  revPerc: string = '.88',
) {
  const priceLeng = `${price}`.split('.').length;
  const costLeng = `${cost}`.split('.').length;
  const newPrice = priceLeng > 1 ? price.toFixed(2) : (price / 100).toFixed(2);
  const newCost = costLeng > 1 ? cost.toFixed(2) : (cost / 100).toFixed(2);
  const revenue = parseFloat(newPrice) * parseFloat(revPerc);
  const obj = {
    price: newPrice,
    cost: newCost,
    revenue,
  };
  const profit = revenue - parseFloat(obj.cost);
  const percent = (profit / obj[percProp]) * 100;
  return {
    profit,
    percent,
    revenue,
  };
}

export function calculateRevenue(
  price: number,
  revPerc: number = 88,
  round: 'up' | 'dn' | 'rd' = 'rd',
) {
  let newPrice = price;
  const stringPrice = `${price}`;
  if (stringPrice.includes('.')) {
    newPrice = convertDollarsToCents(price);
  }
  let revenue;
  switch (round) {
    case 'up':
      revenue = Math.ceil(newPrice * (revPerc / 100));
      break;
    case 'dn':
      revenue = Math.floor(newPrice * (revPerc / 100));
      break;
    default:
      revenue = Math.round(newPrice * (revPerc / 100));
      break;
  }

  return {
    revenue,
  };
}

export function toFixedTwo(input: string | number) {
  return formatCentsToDollars(convertDollarsToCents(input));
}

// export function checkIfCents(input: number | string) {
//   const inputString = typeof input === 'number' ? `${input}` : input;
//   if(inputString.includes('.')) {
//     return false;
//   } else {
//     return true;
//   }
// }
