import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import queryUtils from '@src/utils/queries';

import FontAwesome from '@src/Components/common/FontAwesome';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { Tooltip, IconButton, Grid } from '@mui/material';
import EditInventory from '../InventoryDrawer';

import type { Props } from './types';

function EditButton({ row }: Props) {
  const [open, setOpen] = useState(false);

  const close = () => {
    setOpen(false);
  };
  // const history = useHistory();

  // const viewManufacturerSupplier = () => {
  //   const query = queryUtils.add('id', id);
  //   history.replace({
  //     search: query,
  //   });
  // };

  return (
    <>
      {/* <Tooltip title={`Edit Inventory Item`}>
        <span>
          <IconButton onClick={() => viewManufacturerSupplier()}>
            <EditTwoToneIcon color="primary" />
          </IconButton>
        </span>
      </Tooltip> */}
      <Grid item style={{ justifyContent: 'center' }}>
        <Tooltip title={'Edit Inventory Item'}>
          <span>
            <IconButton onClick={() => setOpen(true)}>
              <EditTwoToneIcon color="primary" />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      {open && <EditInventory isDrawerOpen={open} setDrawerOpen={close} row={row} title={'Edit Inventory'} />}
    </>
    // <FontAwesome
    //   name="edit"
    //   type="fas"
    //   form="fa"
    //   onClick={() => viewManufacturerSupplier()}
    //   style={{ cursor: 'pointer' }}
    // />
  );
}

export default EditButton;
